import * as Klaro from 'klaro';

export default {
    init() {
        const cookieBannerFields = document.querySelector(
            '#cookie-banner-fields'
        ) as HTMLElement;

        if (!cookieBannerFields) return;

        const showModal = Boolean(cookieBannerFields.dataset.showModal);
        let cookieBannerText;

        if (cookieBannerFields.dataset.cookieText) {
            cookieBannerText = JSON.parse(
                cookieBannerFields.dataset.cookieText
            );
        }

        const config = {
            elementID: 'cookie-banner',
            storageMethod: 'cookie',
            storageName: 'klaro-cookie',
            mustConsent: showModal, // Cookie banner is a Modal
            acceptAll: true,
            hideDeclineAll: false,
            translations: {
                de: {
                    privacyPolicyUrl:
                        cookieBannerText['de']['privacyPolicyUrl'],
                    consentNotice: {
                        description:
                            cookieBannerText['de']['consentNoticeDescription'],
                        learnMore:
                            cookieBannerText['de']['consentNoticeLearnMore']
                    },
                    consentModal: {
                        title: cookieBannerText['de']['consentModalTitle'],
                        description:
                            cookieBannerText['de']['consentModalDescription'],
                        privacyPolicy: {
                            text: cookieBannerText['de'][
                                'consentModalPrivacyPolicyText'
                            ]
                        }
                    },
                    ok: cookieBannerText['de']['ok'],
                    decline: cookieBannerText['de']['decline'],
                    acceptSelected: cookieBannerText['de']['acceptSelected'],
                    save: cookieBannerText['de']['save'],
                    close: cookieBannerText['de']['close'],
                    acceptAll: cookieBannerText['de']['acceptAll'],
                    purposes: {
                        // eslint-disable-next-line prettier/prettier
                        functional:
                            cookieBannerText['de']['purposes'][0]['functional'] ? cookieBannerText['de']['purposes'][0]['functional'] : null,
                        essential:
                            // eslint-disable-next-line prettier/prettier
                            cookieBannerText['de']['purposes'][0]['essential'] ? cookieBannerText['de']['purposes'][0]['essential'] : null,
                        // eslint-disable-next-line prettier/prettier
                        analytics:
                            cookieBannerText['de']['purposes'][0]['analytics'] ? cookieBannerText['de']['purposes'][0]['analytics'] : null,
                        marketing:
                            // eslint-disable-next-line prettier/prettier
                            cookieBannerText['de']['purposes'][0]['marketing'] ? cookieBannerText['de']['purposes'][0]['marketing'] : null
                    }
                }
            },
            services: [
                // Essential cookies - technically necessary services for the basic functionality of the website
                {
                    name: 'craft-cms',
                    title: 'Craft CMS',
                    default: true,
                    purposes: ['essential'],
                    cookies: [
                        ['CraftSessionId'],
                        ['*_identity'],
                        ['*_username'],
                        ['CRAFT_CSRF_TOKEN']
                    ],
                    required: true
                },
                {
                    name: 'klaro',
                    title: 'Klaro!',
                    purposes: ['essential'],
                    required: true
                },
                // Functional cookies - necessary to provide features, such as prettier fonts, video playback or interactive Web 2.0 features
                {
                    name: 'youtube',
                    title: 'Youtube',
                    purposes: ['functional'],
                    cookies: [['YSC'], ['VISITOR_INFO1_LIVE']],
                    required: false
                },
                {
                    name: 'vimeo',
                    title: 'Vimeo',
                    purposes: ['functional'],
                    cookies: [
                        ['cebsp_', '/', '.vimeo.com'],
                        ['_ce.clock_data', '/', '.vimeo.com'],
                        ['_ce.clock_event', '/', '.vimeo.com'],
                        ['_ce.s', '/', '.vimeo.com'],
                        ['OptanonAlertBoxClosed', '/', '.vimeo.com'],
                        ['cebs', '/', '.vimeo.com'],
                        ['OptanonConsent', '/', '.vimeo.com'],
                        ['is_logged_in', '/', '.vimeo.com'],
                        ['has_logged_in', '/', '.vimeo.com'],
                        ['vimeo_cart', '/', '.vimeo.com'],
                        ['vimeo', '/', '.vimeo.com'],
                        ['_gcl_au', '/', '.vimeo.com'],
                        ['vuid', '/', '.vimeo.com'],
                        ['__ssid', '/', '.vimeo.com'],
                        ['player', '/', '.vimeo.com'],
                        ['_ttp', '/', '.vimeo.com'],
                        ['sd_client_id', '/', '.vimeo.com'],
                        ['_scid', '/', '.vimeo.com'],
                        ['__q_state_8Q5gZzYPjZ1nTCQs', '/', '.vimeo.com'],
                        ['_uetvid', '/', '.vimeo.com'],
                        ['afUserId', '/', '.vimeo.com'],
                        ['_abexps', '/', '.vimeo.com'],
                        ['_tt_enable_cookie', '/', '.vimeo.com'],
                        ['_lc2_fpi', '/', '.vimeo.com'],
                        ['sd_identity', '/', '.vimeo.com'],
                        ['__cf_bm', '/', '.vimeo.com'],
                        ['_ga', '/', '.vimeo.com'],
                        ['_ga_126VYLCXDY', '/', '.vimeo.com'],
                        ['_gid', '/', '.vimeo.com']
                    ],
                    required: false
                },
                // Analytics - help to observe a user's behavior on the website
                // {
                //     name: 'gtm',
                //     title: 'Google Tag Manager',
                //     purposes: ['analytics'],
                //     cookies: [
                //         [/^_ga.*$/i, '/', '.starter-project.live'],
                //         ['_gid', '/', '.starter-project.live'],
                //         [/^_dc_gtm.*$/i, '/', '.starter-project.live'],
                //         [/^_gtm.*$/i, '/', '.starter-project.live'],
                //         [/^_ga.*$/i, '/', '.starter-project.test'], // for local page
                //         ['_gid', '/', '.starter-project.test'], // for local page
                //         [/^_dc_gtm.*$/i, '/', '.starter-project.test'], // for local page
                //         [/^_gtm.*$/i, '/', '.starter-project.test'] // for local page
                //     ],
                //     required: false
                // },
                {
                    name: 'google-analytics',
                    title: 'Google Analytics',
                    purposes: ['analytics'],
                    cookies: [
                        [/^_ga.*$/i, '/', '.mag.dotsandlines.io'],
                        ['_gid', '/', '.mag.dotsandlines.io'],
                        [
                            /^_ga.*$/i,
                            '/',
                            '.dotsandlines-magazine-craft-cms.test/'
                        ], // for local page
                        ['_gid', '/', '.dotsandlines-magazine-craft-cms.test/'] // for local page
                    ],
                    required: false
                }
                // Marketing - are used to record the behavior of individual users, to analyze the data and, e.g to display personalized advertising
                // {
                //     name: 'marketing',
                //     title: 'Facebook Pixel',
                //     purposes: ['marketing'],
                //     cookies: [],
                //     required: false
                // }
            ]
        };

        // we assign the Klaro module to the window, so that we can access it in JS
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const w = window as any;
        w.klaro = {};
        w.klaro = Klaro;
        w.klaroConfig = config;
        // we set up Klaro with the config
        Klaro.setup(config);
    }
};
