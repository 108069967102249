import './app.scss';
import 'lazysizes';
import swiper from './ts/swiper';
import 'intersection-observer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import navigation from './ts/navigation';
import cookieBanner from './ts/cookie-banner';
import youtubeVideo from './ts/youtube-video';
import scratchOffEffect from './ts/scratch-off-effect';
import animatedBanner from './ts/animated-banner';

// if you need react, just enable it by commenting in this line
// import react from './react/index';

(function () {
    // if you need react, just enable it by commenting in this line
    // react.init();

    navigation.init();

    AOS.init();

    // reload js after load more / view switch
    // (window as any).htmx.on('htmx:afterSwap', function () {
    //     const overviewSections = document.querySelectorAll(
    //         '.overview-section'
    //     ) as NodeListOf<HTMLElement>;
    //     if (overviewSections.length) {
    //         import('./ts/overview-page').then((overviewPage) => {
    //             overviewSections.forEach((section) => {
    //                 overviewPage.default.init(section);
    //             });
    //         });
    //     }
    // });

    const overviewSections = document.querySelectorAll(
        '.overview-section'
    ) as NodeListOf<HTMLElement>;
    if (overviewSections.length) {
        import('./ts/overview-page').then((overviewPage) => {
            overviewSections.forEach((section) => {
                overviewPage.default.init(section);
            });
        });
    }

    const animationSections = document.querySelectorAll(
        '.animation-section'
    ) as NodeListOf<HTMLElement>;
    if (animationSections.length) {
        import('./ts/lottie-animation-section').then((animationSection) => {
            animationSections.forEach((section) => {
                animationSection.default.init(section);
            });
        });
    }

    const imageSliderSections = document.querySelectorAll(
        '.image-slider'
    ) as NodeListOf<HTMLElement>;
    if (imageSliderSections.length) {
        import('./ts/image-slider').then((imageSection) => {
            imageSliderSections.forEach((section) => {
                imageSection.default.init(section);
            });
        });
    }

    const heroVideoSections = document.querySelectorAll(
        '.hero-bg-video'
    ) as NodeListOf<HTMLElement>;
    if (heroVideoSections.length) {
        import('./ts/hero-bg-video').then((heroVideo) => {
            heroVideoSections.forEach((section) => {
                heroVideo.default.init(section);
            });
        });
        import('./ts/video-overlay').then((heroVideo) => {
            heroVideoSections.forEach((section) => {
                heroVideo.default.init(section);
            });
        });
    }

    const interviewSections = document.querySelectorAll(
        '.interview-section'
    ) as NodeListOf<HTMLElement>;
    if (interviewSections.length) {
        import('./ts/interview').then((interviewSection) => {
            interviewSections.forEach((section) => {
                interviewSection.default.init(section);
            });
        });
    }

    const imageStackSections = document.querySelectorAll(
        '.image-stack-section'
    ) as NodeListOf<HTMLElement>;
    if (imageStackSections.length) {
        import('./ts/image-stack-animation').then((imageStackSection) => {
            imageStackSections.forEach((section) => {
                imageStackSection.default.init(section);
            });
        });
    }

    animatedBanner.init();

    scratchOffEffect.init();

    // Init all swiper if there are some
    const allSwiper = document.querySelectorAll(
        '.swiper-container'
    ) as NodeListOf<HTMLElement>;
    if (allSwiper.length) {
        allSwiper.forEach(function (swiperElement) {
            if (!swiperElement.dataset.swiperOptions) return;
            // Set swiper element and swiper options from data-attribute
            swiper.init(
                swiperElement,
                JSON.parse(swiperElement.dataset.swiperOptions)
            );
        });
    }

    const animatedBannersSmall = document.querySelectorAll(
        '.animated-banner-small'
    ) as NodeListOf<HTMLElement>;
    if (animatedBannersSmall.length) {
        import('./ts/animated-banner-small').then((animatedBannerSmall) => {
            animatedBannersSmall.forEach((section) => {
                animatedBannerSmall.default.init(section);
            });
        });
    }

    cookieBanner.init();

    const youtubeVideoContainers = document.querySelectorAll(
        '.youtube-video-container'
    ) as NodeListOf<HTMLElement>;
    if (youtubeVideoContainers.length) {
        youtubeVideoContainers.forEach((container) => {
            youtubeVideo.init(container);
        });
    }

    const videoSectionContainers = document.querySelectorAll(
        '.video-section'
    ) as NodeListOf<HTMLElement>;
    if (videoSectionContainers.length) {
        import('./ts/video-section').then((videoSection) => {
            videoSectionContainers.forEach((section) => {
                videoSection.default.init(section);
            });
        });
    }

    const heroHomeSections = document.querySelectorAll(
        '.hero-home-textblock'
    ) as NodeListOf<HTMLElement>;
    if (heroHomeSections.length) {
        import('./ts/home-hero-overlay').then((heroHomeSection) => {
            heroHomeSections.forEach((section) => {
                heroHomeSection.default.init(section);
            });
        });
    }

    const slidingBanners = document.querySelectorAll(
        '.sliding-banner'
    ) as NodeListOf<HTMLElement>;
    if (slidingBanners.length) {
        import('./ts/sliding-banner').then((slidingBanner) => {
            slidingBanners.forEach((component) => {
                slidingBanner.default.init(component);
            });
        });
    }

    const heroHomeTextblocks = document.querySelectorAll(
        '.hero-home-textblock'
    ) as NodeListOf<HTMLElement>;
    if (heroHomeTextblocks.length) {
        import('./ts/hero-home-animation').then((heroHomeAnimation) => {
            heroHomeTextblocks.forEach((textblock) => {
                heroHomeAnimation.default.init(textblock);
            });
        });
    }
})();
